<template>
    <!-- <v-container fluid class="pa-16"> -->
    <!-- <div class="red wrapper"> -->
    <div
        class="d-flex flex-column"
        :style="{ height: viewHeight + 'px' }"
        v-resize="onResize"
    >
        <div class="filter-wrapper" v-if="loaded && !loading">
            <FilterBar></FilterBar>
        </div>
        <!-- <div class="wrapper"
        :style="{ height: viewHeight }"
        v-resize="onResize"> -->
        <div
            v-if="loading"
            class="d-flex justify-center align-center fill-height"
        >
            <v-progress-circular
                color="primary"
                size="75"
                indeterminate
            ></v-progress-circular>
        </div>
        <div
            v-if="error"
            class="d-flex justify-center align-center fill-height"
        >
            <v-alert type="error">
                {{ error }}
            </v-alert>
        </div>
        <div
            v-if="loaded && !loading && multiFarmPromos.length === 0"
            class="d-flex justify-center align-center fill-height"
        >
            <v-alert border="right" colored-border type="error" elevation="2">
                Nessun risultato
            </v-alert>
        </div>
        <div
            v-if="loaded && !loading && multiFarmPromos.length !== 0"
            class="list-wrapper"
            :style="{ height: scrollerHeight + 'px' }"
        >
            <PromosList
                :promos="filteredItems"
                :scrollerHeight="scrollerHeight"
                @selected="selectPromo"
            ></PromosList>
        </div>
        <v-fab-transition>
            <v-btn
                color="pink"
                dark
                fab
                class="v-btn-add flex-item-bottom"
                @click="addAction"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>
    </div>
    <!-- </div> -->
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import * as farmAction from "../../store/actions/farm";
import * as snackbarAction from "../../store/actions/snackbar";
import * as multiFarmPromoAction from "../../store/actions/multiFarmPromo";
import * as promoActions from "../../store/actions/promo";
import Comunicazione from "../../viewmodels/comunicazione.js";

export const FilterBar = () => import("../../components/filters/FilterBar.vue");
export const PromosList = () => import("../../components/farms/PromosList.vue");
// export const PromoPage = () => import("../../components/promo/PromoPage");
import { compareDate } from "../../utils/utils";

export default {
    components: {
        FilterBar,
        PromosList,
        // PromoPage
    },
    data: () => ({
        id: 0,
        promo: null,
        toApprove: null,
        scrollerHeight: 0,
        viewHeight: 0
    }),
    computed: {
        ...mapState({
            farms: state => state.farmModule.farms,
            multiFarmPromos: state => state.promoMultiFarmModule.promos,
            loading: state => state.promoMultiFarmModule.loading,
            loaded: state => state.promoMultiFarmModule.loaded,
            error: state => state.promoMultiFarmModule.error,
            promoFilter: state => state.filterModule.multiFarmsFilter,
            getFilterBarHeight: state => state.appModule.filterBarHeight
        }),

        filteredItems() {
            let dt;
            dt = new Date();
            dt.setHours(0, 0, 0, 0);
            // console.log('SONO QUI', this.multiFarmPromos, this.promoFilter)
            return this.multiFarmPromos.filter(promo => {
                const expired = compareDate(promo.FineValidita) === 1;
                const toPublish = compareDate(promo.InizioValidita) === -1;
                const deleted = promo.Action === 1;
                const suspended = promo.Sospeso === true;
                const active =
                    !expired &&
                    !toPublish &&
                    !deleted &&
                    !suspended &&
                    promo.Action !== 1;

                const f1 = this.promoFilter.expired && expired;
                const f2 = this.promoFilter.deleted && deleted;
                const f3 = this.promoFilter.toPublish && toPublish;
                const f4 = this.promoFilter.suspended && suspended;
                const f5 = this.promoFilter.active && active;
                const f6 =
                    this.promoFilter.text.length === 0
                        ? true
                        : promo.Titolo.toLowerCase().indexOf(
                              this.promoFilter.text
                          ) !== -1;

                const f7 = this.promoFilter.fromEnabled
                    ? new Date(promo.InizioValidita).setHours(0, 0, 0, 0) >=
                      new Date(this.promoFilter.from).setHours(0, 0, 0, 0)
                    : true;

                const f8 = this.promoFilter.toEnabled
                    ? new Date(promo.FineValidita).setHours(0, 0, 0, 0) <=
                      new Date(this.promoFilter.to).setHours(0, 0, 0, 0)
                    : true;

                return (
                    (f1 || f2 || f3 || (f4 && !expired) || f5) && f6 && f7 && f8
                );
            });
        }
    },

    methods: {
        ...mapActions({
            getFarms: farmAction.FARM_GET_FARMS,
            showMessage: snackbarAction.SNACKBAR_SHOW,
            getMultiPromos: multiFarmPromoAction.MULTI_FARM_FETCHPROMOS,
            createPromo: promoActions.PROMO_NEWPROMO,
        }),

        ...mapMutations({
            appendFarmsPromo: multiFarmPromoAction.MULTI_FARM_APPEND
        }),

        addAction(event) {
            const comm = new Comunicazione();
            let data = comm.setFarms(this.farms);
            // console.log(data)
            // console.log(this.$route.params.storeId)
            this.$formdialog
                .open({
                    type: "",
                    title: "Aggiungi Comunicazione",
                    data: data,
                    formName: "AddPromoMulti",
                    fullScreen: true
                })
                .then(async response => {
                    this.$formdialog.showProgress(true);

                    if (response.PuntiVenditaInclusi.length === 0) {
                        this.showMessage(
                            "Attenzione non ci sono Punti Vendita Applicati!"
                        );
                    } else {
                        const result = await this.createPromo({
                            farmId: 0,
                            promo: response
                        });
                        this.appendFarmsPromo(result);
                    }
                    console.log(response);
                    // this.appendStorePromo(result);
                    // console.log(result);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    this.$formdialog.showProgress(false);
                    this.$formdialog.destroy();
                });
        },

        selectPromo(promo) {
            // console.log(promo);
            // setTimeout(() => {
            //     const selected = this.multiFarmPromos.filter(
            //         x => x.IdComunicazioneGenerica === promo
            //     );
            //     if (selected.length !== 0) {
            //         this.promo = selected[0];
            //     }
            // }, 1000);
            this.$router.push({
                name: "MultiPromo",
                params: { promoId: promo }
            }).catch(() => {});
        },

        onResize() {
            this.$nextTick(function() {
                const vw = Math.max(
                    document.documentElement.clientWidth || 0,
                    window.innerWidth || 0
                );
                const vh = Math.max(
                    document.documentElement.clientHeight || 0,
                    window.innerHeight || 0
                );
                this.scrollerHeight = vh - (this.$vuetify.application.top || 112) - this.getFilterBarHeight - 32;
                this.viewHeight = vh - (this.$vuetify.application.top || 112) - 32;
                // console.log(this.scrollerHeight, this.viewHeight, this.getFilterBarHeight)
                //console.log(this.$refs.filterBar.clientHeight)
                /*                 console.log(vw, vh);
                console.log(this.$vuetify); */
            });
        }
    },
    beforeMount() {
        this.getMultiPromos().then(state => {});
    },
    mounted() {}
};
</script>

<style scoped>
.filter-wrapper {
    width: 100%;
    padding: 16px;
    /* margin: 16px; */
    /* box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12); */
}

.list-wrapper {
    width: 100%;
}
.flex-item-bottom {
    position: absolute;
    right: 28px;
    bottom: 28px;
    /* bottom: -56px */
}
</style>
